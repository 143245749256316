import React, { useState, useCallback } from 'react'
import 'styled-components/macro'

import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading } from 'components/misc/Headings'
import { SendButton } from 'components/misc/Buttons'
import SendNowForm from 'components/forms/SendNowForm'

export default ({
    title = 'Sends',
}) => {
    const [showSendNow, setShowSendNow] = useState(false)
    const toggleShowSendNow = useCallback(() => {
        setShowSendNow(!showSendNow)
    }, [setShowSendNow, showSendNow])

    const sendCallback = useCallback(() => {
        toggleShowSendNow()
    }, [toggleShowSendNow])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    
                        <HeadingRow>
                            <Heading>{title}</Heading>
                        </HeadingRow>

                        <SendButton onClick={toggleShowSendNow}>
                            Send Now
                        </SendButton>

                        {showSendNow &&(
                            <SendNowForm callback={sendCallback} />
                        )}

                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
