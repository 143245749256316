import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import tw from 'twin.macro'
import JoditEditor from 'jodit-react'

import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const Content = tw.div `rounded bg-gray-700 outline-none xl:mx-auto p-4 my-4`
const FormContainer = styled.div `
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl italic`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const Column = tw.div `sm:w-full flex flex-col mt-4`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const Input = tw.input``
const Select = tw.select``
const Option = tw.option``
const SubmitButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`
const CancelButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-red-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-red-700 hocus:-translate-y-px hocus:shadow-xl ml-4`
const HtmlEditorContainer = tw.div`relative text-gray-800`

export default ({ toggle, item }) => {
    const { authenticated, toggleLoading } = useAuth()
    const editor = useRef(null)
    const config = {
		readonly: false
	}

    const [emails, setEmails] = useState('')
    const [content, setContent] = useState('')
    const [link, setLink] = useState('')
    const [subject, setSubject] = useState('')
    const [fromName, setFromName] = useState('')
    const [fromEmail, setFromEmail] = useState('')
    const [replytoemail, setReplytoemail] = useState('')
    const [insertUnsubscribe, setInsertUnsubscribe] = useState('0')

    const [fromNames, setFromNames] = useState([])
    const [fromEmails, setFromEmails] = useState([])

    const clearForm = useCallback(() => {
        setEmails('')
        setContent('')
        setLink('')
        setSubject('')
        setFromName('')
        setFromEmail('')
        setReplytoemail('')
    }, [setEmails, setContent, setLink, setSubject, setFromName, setFromEmail, setReplytoemail])

    const submit = useCallback((e) => {
        e.preventDefault()

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            email_id: item.id,
            test_mode: true,
            contacts: emails,
            custom_content: content,
            custom_link: link,
            custom_subject: subject,
            custom_from_name: fromName,
            custom_from_email: fromEmail,
            custom_reply_to_email: replytoemail,
            insert_unsubscribe: insertUnsubscribe
        }

        toggleLoading(true)
        api.post('/send_now', data)
            .then((response) => {
                const { data } = response
                const message = data.message ? data.message : 'Email sent!'
                toast.success(message)
                clearForm()
                if (toggle) toggle()
            })
            .catch((response) => {
                const { data } = response
                const message = data.message ? data.message : 'An error ocurred, try again!'
                toast.error(message)
            })
            .finally(() => {
                toggleLoading(false)
            })

    }, [authenticated, item, emails, content, link, subject, fromName, fromEmail, replytoemail, insertUnsubscribe, clearForm, toggle, toggleLoading])

    const getFromNames = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/from_name_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setFromNames(data.data)
                } else {
                    setFromNames([])
                }
            })
            .catch(() => {
                toast.error('Error on getting From Names')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setFromNames, toggleLoading])

    const getFromEmails = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/from_email_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setFromEmails(data.data)
                } else {
                    setFromEmails([])
                }
            })
            .catch(() => {
                toast.error('Error on getting From Names')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setFromEmails, toggleLoading])

    useEffect(() => {
        getFromNames()
        getFromEmails()
    }, [getFromNames, getFromEmails])

    return (  
        <Content>
            <FormContainer>
                <div tw = 'mx-auto max-w-4xl'>
                    <h3>Send Test Email</h3>
                    {item && item.name && (<h2>{item.name}</h2>)}
                    
                    <Column>

                        <InputContainer>
                            <Label htmlFor='custom_subject'>Subject</Label>
                            <Input
                            type="text"
                            id="custom_subject"
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                            />
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor='email'>Emails (comma separated)</Label>
                            <Input
                            type="text"
                            id="email"
                            onChange={(e) => setEmails(e.target.value)}
                            value={emails}
                            />
                        </InputContainer>

                        {item && item.has_custom_content && parseInt(item.has_custom_content) === 1 && (
                            <InputContainer>
                                <Label htmlFor='custom_content'>Content</Label>
                                <HtmlEditorContainer>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        config={config}
                                        tabIndex={1}
                                        onBlur={e => setContent(e.srcElement.innerHTML)}
                                    />
                                </HtmlEditorContainer>
                            </InputContainer>
                        )}

                        {item && item.has_custom_link && parseInt(item.has_custom_link) === 1 && (
                            <InputContainer>
                                <Label htmlFor='custom_link'>Link</Label>
                                <Input
                                type="text"
                                id="custom_link"
                                onChange={(e) => setLink(e.target.value)}
                                value={link}
                                />
                            </InputContainer>
                        )}

                        <InputContainer>
                            <Label htmlFor='from_name'>From name</Label>
                            <Select 
                            id='from_name' 
                            onChange={(e) => setFromName(e.target.value)}
                            name='from_name'>
                                <Option>Select...</Option>
                                {fromNames.map((item) => (
                                    <Option value={item.name} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor='from_email'>From email</Label>
                            <Select 
                            id='from_email' 
                            onChange={(e) => setFromEmail(e.target.value)}
                            name='from_email'>
                                <Option>Select...</Option>
                                {fromEmails.map((item) => (
                                    <Option value={item.email} key={item.id}>{item.email}</Option>
                                ))}
                            </Select>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor='replyto_email'>Reply-to email</Label>
                            <Select 
                            id='replyto_email' 
                            onChange={(e) => setReplytoemail(e.target.value)}
                            name='replyto_email'>
                                <Option>Select...</Option>
                                {fromEmails.map((item) => (
                                    <Option value={item.email} key={item.id}>{item.email}</Option>
                                ))}
                            </Select>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor='insert_subscribe'>Unsubscribe Link</Label>
                            <Select 
                                id='insert_subscribe'
                                name='insert_subscribe'
                                onChange={(e) => setInsertUnsubscribe(e.target.value)} >
                                    <Option value="0">None</Option>
                                    <Option value="1">Lugli Group</Option>
                                    <Option value="2">Lugli Training</Option>
                            </Select>
                        </InputContainer>
                        
                    </Column>

                    <SubmitButton onClick={submit}>Send Test</SubmitButton>
                    <CancelButton onClick={toggle}>Close</CancelButton>
                    
                </div>
                
            </FormContainer>
            
        </Content>
    )
}
