import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import 'styled-components/macro'

import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const Container = tw.div `relative`
const Content = tw.div `max-w-screen-xl mx-auto`
const FormContainer = styled.div `
  ${tw`px-10 py-5 bg-gray-700 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const Column = tw.div `sm:w-full flex flex-col`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const Input = tw.input ``
const SubmitButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`

export default ({ callback }) => {
    const { authenticated, toggleLoading } = useAuth()

    const [email, setEmail] = useState('')

    const submit = useCallback((e) => {
        e.preventDefault()

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            email_adress: email
        }
        toggleLoading(true)
        api.post('/from_email_insert', data)
            .then(() => {
                toast.success('Added')
                if (callback) callback()
            })
            .catch(() => {
                toast.error('An error ocurred, try again!')
            })
            .finally(() => {
              toggleLoading(false)
            })

    }, [authenticated, email, callback, toggleLoading])

    return (
        <Container>
            <Content>
                <FormContainer>
                    <div tw = 'mx-auto max-w-4xl'>
                        <h2>Create sending email</h2>
                        
                        <Column>

                            <InputContainer>
                                <Label htmlFor='email'>Email</Label>
                                <Input 
                                    id='email' 
                                    type='email' 
                                    name='email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    placeholder='john@google.com' />
                            </InputContainer>
                            
                        </Column>

                        <SubmitButton onClick={submit}>Add</SubmitButton>
                        
                    </div>
                </FormContainer>
            </Content>
        </Container>
    )
}