import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container as ContainerBase } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import 'styled-components/macro'
import LogoFull from 'images/platform_logo_full.png'
import logo from 'images/platform_logo.png'
import { ReactComponent as LoginIcon } from 'feather-icons/dist/icons/log-in.svg'

import { toast } from 'react-toastify'
import { useAuth } from 'context/AuthContext'

const Container = tw(ContainerBase)`min-h-screen bg-gray-900 text-white font-medium flex justify-center -m-8`
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`
const LogoLink = tw.a``
const LogoImage = tw.img`h-20 mx-auto`
const MainContent = tw.div`mt-12 flex flex-col items-center`
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`
const FormContainer = tw.div`w-full flex-1 mt-8`

const Form = tw.form`mx-auto max-w-xs`
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-gray-600 text-gray-100 w-full py-4 rounded-lg hover:bg-gray-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`
const IllustrationImage = styled.div`
  ${props => `background-image: url('${props.imageSrc}');`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`

export default ({
  logoLinkUrl = '/',
  headingText = 'Sign In',
  submitButtonText = 'Sign In',
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = '/forgotpassword'
}) => {
  const history = useHistory()
  const { signIn, toggleLoading } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const login = useCallback(async (e) => {
    e.preventDefault()
    if (!email || !password) {
      toast.error('Email/password is required!')
      return
    }
    toggleLoading(true)
    try {
      await signIn({ email, password })
      toggleLoading(false)
      history.push('/dashboard')
    } catch(err) {
      const status = err.response.status

      switch (status) {
        case 403:
          toast.error('Invalid login/password or error')
          break;
        case 500:
          toast.error('Internal server error')
          break;
        default:
          toast.error('General error')
          break;
      }
      toggleLoading(false)
    }
  }, [email, password, signIn, history, toggleLoading])

  return (
    <AnimationRevealPage disabled={true}>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form>
                  <Input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                  <SubmitButton type='submit' onClick={login}>
                    <SubmitButtonIcon className='icon' />
                    <span className='text'>{submitButtonText}</span>
                  </SubmitButton>
                </Form>
                <p tw='mt-6 text-xs text-gray-600 text-center'>
                  <a href={forgotPasswordUrl} tw='border-b border-gray-500 border-dotted'>
                    Forgot Password ?
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={LogoFull} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
}
