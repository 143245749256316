import React from "react";
import tw from "twin.macro";
import "styled-components/macro";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const TwoColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

export default () => {
  return (
    <Container>
      <Content>
        <TwoColRow>
          <LogoContainer>
            <LogoText>Lugli Group Email Marketing Platform</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2021. All Rights Reserved.</CopywrightNotice>
        </TwoColRow>
      </Content>
    </Container>
  );
};
