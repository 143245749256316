import React from 'react'
import 'styled-components/macro'
import tw from 'twin.macro'

import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { SectionHeading } from 'components/misc/Headings'

import Menu from 'components/features/Menu'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900`

export default ({
  title = 'Dashboard',
}) => {
  return (
    <AnimationRevealPage disabled={true}>
        <Header />
        <Container>
          <ContentWithPaddingXl>

              <HeadingRow>
                  <Heading>{title}</Heading>
              </HeadingRow>

              <Menu />

          </ContentWithPaddingXl>
        </Container>
        <Footer />
    </AnimationRevealPage>
  )
}
