import React from 'react'
import AppProvider from 'context/Provider'

import 'tailwindcss/dist/base.css'
import 'styles/globalStyles.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import Routes from 'routes/index'
import Loader from 'helpers/Loader'

import { BrowserRouter as Router } from 'react-router-dom'

export default function App() {
  return (
    <AppProvider>
      <Router>
        <Loader />
        <Routes />
        <ToastContainer />
      </Router>
    </AppProvider>
  )
}