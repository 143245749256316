import tw from 'twin.macro'

export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const CreateButton = tw.button`px-6 py-2 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const AddButton = tw.button`my-3 px-6 py-2 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const SendButton = tw.button`px-6 py-2 font-bold rounded bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export const EditButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-gray-500 text-gray-100 hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const ManageButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-teal-500 text-gray-100 hocus:bg-teal-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const ManageButtonLink = tw.a`px-3 py-2 ml-3 font-bold rounded bg-teal-500 text-gray-100 hocus:bg-teal-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const GreenButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const BlueButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const ViewButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-green-500 text-gray-100 hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const ViewLink = tw.a`px-3 py-2 ml-3 font-bold rounded bg-green-500 text-gray-100 hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`
export const RemoveButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-red-500 text-white hocus:bg-red-700 focus:shadow-outline focus:outline-none transition duration-300`
export const EnableButton = tw.button`px-3 py-2 ml-3 font-bold rounded bg-gray-500 text-gray-100 hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`