import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading, SubheadingUpercase, SubHeadingRow } from 'components/misc/Headings'
import { CreateButton, RemoveButton, EditButton } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn } from 'components/misc/List'

import CreateUserModal from 'components/modals/CreateUser'
import ChangePassword from 'components/modals/ChangePassword'

import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

export default ({
    title = 'Users',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [createUserModalOpen, setCreateUserModalOpen] = useState(false)
    const toggleCreateModal = () => setCreateUserModalOpen(!createUserModalOpen)

    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)
    const toggleChangePasswordModal = () => setChangePasswordModalOpen(!changePasswordModalOpen)

    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState('enabled')
    const [selectedItem, setSelectedItem] = useState()

    const getUsers = useCallback((filter = 'enabled') => {
        const url = (filter === 'enabled') ? '/user_list' : '/user_list_disabled'
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post(url , data)
            .then((response) => {
                const { data } = response
                setUsers(data.data)
            })
            .catch(() => {
                toast.error('Error getting users')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setUsers, toggleLoading])

    const insertCallback = useCallback(() => {
        getUsers(filter)
    }, [getUsers, filter])

    const disableEnableUser = useCallback((type, id) => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            user_id: id
        }
        const url = (type === 'enable') ? '/user_enable' : '/user_disable'
        
        if (url && data) {
            toggleLoading(true)
            api.post(url, data)
            .then(() => {
                getUsers(filter)
                toast.success('User updated')
            })
            .catch(() => {
                toast.error('Error on changing user status')
            })
            .finally(() => {
                toggleLoading(false)
            })
        }
    }, [authenticated, getUsers, filter, toggleLoading])

    useEffect(() => {
        getUsers()
    }, [getUsers])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                    <HeadingRow>
                        <Heading>{title}</Heading>
                    </HeadingRow>

                    <SubHeadingRow>
                        <SubheadingUpercase>{filter}</SubheadingUpercase>
                    </SubHeadingRow>

                    <CreateButton onClick={toggleCreateModal}>
                        Create user
                    </CreateButton>

                    {filter === 'enabled' && (
                        <EditButton
                            onClick={() => {
                                setFilter('disabled')
                                getUsers('disabled')
                            }}
                        >
                            Show disabled
                        </EditButton>
                    )}
                    {filter === 'disabled' && (
                        <EditButton
                            onClick={() => {
                                setFilter('enabled')
                                getUsers('enabled')
                            }}
                        >
                            Show enabled
                        </EditButton>
                    )}

                    <ListContainer>
                        {users && users.map((item) => (
                            <ListItem key={item.id}>
                                <LeftColumn>
                                {item.email === authenticated.email && (<strong>[YOU]</strong>)} {item.name} <i>[{item.email}]</i>
                                </LeftColumn>
                                <RightColumn>
                                    {item.email !== authenticated.email && (
                                        <EditButton onClick={() => {
                                            setSelectedItem(item)
                                            toggleChangePasswordModal()
                                        }}>
                                            Change password
                                        </EditButton>
                                    )}
                                    {item.status === "1" && item.email !== authenticated.email && (
                                        <RemoveButton onClick={() => disableEnableUser('disable', item.id)}>Disable user</RemoveButton>
                                    )}
                                    {item.status === "0" && item.email !== authenticated.email && (
                                        <CreateButton onClick={() => disableEnableUser('enable', item.id)}>Enable user</CreateButton>
                                    )}
                                    
                                </RightColumn>
                            </ListItem>
                        ))}
                    </ListContainer>

                    <CreateUserModal
                        modalIsOpen={createUserModalOpen}
                        toggleModal={toggleCreateModal}
                        callback={insertCallback}
                    />

                    <ChangePassword
                        modalIsOpen={changePasswordModalOpen}
                        toggleModal={toggleChangePasswordModal}
                        item={selectedItem}
                        callback={insertCallback}
                    />

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
