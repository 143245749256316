import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading } from 'components/misc/Headings'
import { CreateButton, EditButton, GreenButton, RemoveButton, BlueButton } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn } from 'components/misc/List'

import CreateEmailForm from 'components/forms/CreateEmail'
import EditEmailForm from 'components/forms/EditEmail'
import SendtTestEmailNowForm from 'components/forms/SendTestEmailNow'
import SendEmailNowForm from 'components/forms/SendEmailNow'

import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

export default ({
    title = 'Emails',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [openList, setOpenList] = useState(true)

    const [createEmailFormOpen, setCreateEmailFormOpen] = useState(false)
    const toggleCreateForm = () => {
        setCreateEmailFormOpen(!createEmailFormOpen)
        setOpenList(!openList)
    }

    const [editEmailFormOpen, setEditEmailFormOpen] = useState(false)
    const toggleEditForm = () => {
        setEditEmailFormOpen(!editEmailFormOpen)
        setOpenList(!openList)
    }

    const [sendTestFormOpen, setSendTestFormOpen] = useState(false)
    const toggleSendTestForm = () => {
        setSendTestFormOpen(!sendTestFormOpen)
        setOpenList(!openList)
    }

    const [sendEmailFormOpen, setSendEmailFormOpen] = useState(false)
    const toggleSendNowForm = () => {
        setSendEmailFormOpen(!sendEmailFormOpen)
        setOpenList(!openList)
    }    

    const [selectedItem, setSelectedItem] = useState()

    const [emails, setEmails] = useState([])

    const getEmails = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/email_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setEmails(data.data)
                }
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setEmails, toggleLoading])

    const insertCallback = useCallback(() => {
        getEmails()
    }, [getEmails])

    const removeEmail = useCallback((id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    email_id: id
                }
        
                toggleLoading(true)
                api.post('/email_remove', data)
                    .then(() => {
                        getEmails()
                        toast.success('Email removed!')
                    })
                    .catch(() => {
                        console.log('error')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, getEmails, toggleLoading])

    useEffect(() => {
        getEmails()
    }, [getEmails])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                    <HeadingRow>
                        <Heading>{title}</Heading>
                    </HeadingRow>

                    <CreateButton onClick={toggleCreateForm}>
                        Create email
                    </CreateButton>

                    {/* Form to Create new Email */}
                    {createEmailFormOpen && (
                        <CreateEmailForm
                            toggle={toggleCreateForm}
                            callback={insertCallback}
                        />
                    )}

                    {/* Form to Edit Email */}
                    {editEmailFormOpen && (
                        <EditEmailForm
                            toggle={toggleEditForm}
                            callback={insertCallback}
                            item={selectedItem}
                        />
                    )}
                    
                    {/* Form to Send Test Email */}
                    {sendTestFormOpen && (
                        <SendtTestEmailNowForm
                            toggle={toggleSendTestForm}
                            item={selectedItem}
                        />
                    )}

                    {/* Form to Send Email */}
                    {sendEmailFormOpen && (
                        <SendEmailNowForm
                            toggle={toggleSendNowForm}
                            item={selectedItem}
                        />
                    )}
                    
                    {openList && (
                        <ListContainer>
                            {emails && emails.map((item) => (
                                <ListItem key={item.id}>
                                    <LeftColumn>{item.name}</LeftColumn>
                                    <RightColumn>
                                        <EditButton onClick={() => {
                                            setSelectedItem(item)
                                            toggleEditForm()
                                        }}>
                                            Edit
                                        </EditButton>
                                        <BlueButton onClick={() => {
                                            setSelectedItem(item)
                                            toggleSendTestForm()
                                        }}>
                                            Send Test
                                        </BlueButton>
                                        <GreenButton onClick={() => {
                                            setSelectedItem(item)
                                            toggleSendNowForm()
                                        }}>
                                            Send Now
                                        </GreenButton>
                                        <RemoveButton onClick={() => removeEmail(item.id)}>Remove</RemoveButton>
                                    </RightColumn>
                                </ListItem>
                            ))}
                        </ListContainer>
                    )}

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
