import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import 'styled-components/macro'

import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading, SubheadingH3, SubheadingH4 } from 'components/misc/Headings'
import { RemoveButton, AddButton } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn } from 'components/misc/List'

import Swal from 'sweetalert2'
import Select from 'react-select';
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

export default () => {
    const title = 'Manage List'
    const { id } = useParams()
    const { authenticated, toggleLoading } = useAuth()

    const [contacts, setContacts] = useState([])
    const [listinfo, setListinfo] = useState({})
    const [allContacts, setAllContacts] = useState([])
    const [targetAddContact, setTargetAddContact] = useState()

    const getContacts = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            list_id: id
        }
        toggleLoading(true)
        api.post('/list_contacts', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setContacts(data.data)
                } else {
                    setContacts([])
                }

                if (data.info) {
                    setListinfo(data.info)
                } else {
                    setListinfo({})
                }
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [id, authenticated, setContacts, toggleLoading])

    const getAllContacts = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/contact_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setAllContacts(data.data)
                    const tmpContacts = data.data
                    const contactsArray = []
                    tmpContacts.forEach((item) => {
                        contactsArray.push({
                            value: item.id,
                            label: `${item.first_name} [${item.email}]`
                        })
                    })
                    setAllContacts(contactsArray)
                } else {
                    setAllContacts([])
                }
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, toggleLoading])

    const removeContact = useCallback((contact_id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    list_id: id,
                    contact_id: contact_id
                }
        
                toggleLoading(true)
                api.post('/list_remove_contact', data)
                    .then(() => {
                        getContacts()
                        toast.success('Contact removed from list!')
                    })
                    .catch(() => {
                        console.log('error')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, id, getContacts, toggleLoading])

    const addContactToList = useCallback(() => {
        if (!targetAddContact || targetAddContact === '' || targetAddContact === ' ' || typeof(targetAddContact) === undefined) return

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            list_id: id,
            contact_id: targetAddContact
        }

        toggleLoading(true)
        api.post('/list_add_contact', data)
            .then(() => {
                getContacts()
                toast.success('Contact added to List!')
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, id, targetAddContact, getContacts, toggleLoading])

    useEffect(() => {
        getContacts()
    }, [getContacts])

    useEffect(() => {
        getAllContacts()
    }, [getAllContacts])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
                <Container>
                    <ContentWithPaddingXl>

                            <HeadingRow>
                                <Heading>{listinfo && listinfo.name}</Heading>
                            </HeadingRow>

                            <HeadingRow>
                                <SubheadingH4>{title}</SubheadingH4>
                            </HeadingRow>                       

                            <ListContainer>
                                {contacts && contacts.map((item) => (
                                    <ListItem key={item.id}>
                                        <LeftColumn>{item.first_name} [<i>{item.email}</i>]</LeftColumn>
                                        <RightColumn>
                                            <RemoveButton onClick={() => removeContact(item.id)}>Remove</RemoveButton>
                                        </RightColumn>
                                    </ListItem>
                                ))}
                            </ListContainer>

                            <ListContainer>
                                <SubheadingH3>Add Contact to List</SubheadingH3>
                                <Select
                                onChange = {(e) => {
                                    setTargetAddContact(e.value)
                                }}
                                options={allContacts} />
                                <AddButton onClick = {(e) => {
                                    e.preventDefault()
                                    addContactToList()
                                }}>
                                    Add
                                </AddButton>
                            </ListContainer>

                    </ContentWithPaddingXl>
                </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
