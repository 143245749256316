import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import 'styled-components/macro'
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg'

import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const Container = tw.div `relative`
const Content = tw.div `max-w-screen-xl mx-auto py-10 lg:py-10`
const FormContainer = styled.div `
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-700 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const Column = tw.div `sm:w-full flex flex-col`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const Select = tw.select``
const Option = tw.option``
const SubmitButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`
const SvgDotPattern = tw(SvgDotPatternIcon)
`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default ({ callback }) => {
    const { authenticated } = useAuth()

    const [lists, setLists] = useState([])
    const [emails, setEmails] = useState([])

    const [listId, setListId] = useState()
    const [emailId, setEmailId] = useState()

    const submit = useCallback((e) => {
        e.preventDefault()

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            list_id: listId,
            email_id: emailId
        }

        api.post('/send_now', data)
            .then((response) => {
                console.log(response)
                toast.success('Email sent!')
                if (callback) callback()
            })
            .catch(() => {
                toast.error('An error ocurred, try again!')
            })

    }, [authenticated, listId, emailId, callback])

    const getLists = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        api.post('/list_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setLists(data.data)
                    setListId(data.data[0].id)
                }
            })
            .catch(() => {
                console.log('error')
            })
      }, [authenticated, setLists, setListId])

    const getEmails = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        api.post('/email_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setEmails(data.data)
                    setEmailId(data.data[0].id)
                }
            })
            .catch(() => {
                console.log('error')
            })
    }, [authenticated, setEmails, setEmailId])
    
    useEffect(() => {
        getLists()
        getEmails()
    }, [getLists, getEmails])

    return (
        <Container>
            <Content>
                <FormContainer>
                    <div tw = 'mx-auto max-w-4xl'>
                        <h2>Send Now</h2>
                        
                        <Column>

                            <InputContainer>
                                <Label htmlFor='email_id'>Email</Label>
                                <Select 
                                id='email_id' 
                                onChange={(e) => setEmailId(e.target.value)}
                                name='email_id'>
                                {emails.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                                </Select>
                            </InputContainer>
                            
                            <InputContainer>
                                <Label htmlFor='list_id'>List</Label>
                                <Select 
                                id='list_id' 
                                onChange={(e) => setListId(e.target.value)}
                                name='list_id'>
                                {lists.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                                </Select>
                            </InputContainer>
                            
                        </Column>

                        <SubmitButton onClick={submit}>Send</SubmitButton>
                        
                    </div>
                    <SvgDotPattern />
                </FormContainer>
            </Content>
        </Container>
    )
}