import tw from 'twin.macro'

export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
export const Subheading = tw.h5`font-bold text-primary-500`
export const SubheadingUpercase = tw.h5`font-bold text-primary-500 uppercase`
export const Heading = tw.h2`text-3xl sm:text-4xl font-black tracking-wide text-center text-gray-800`
export const SubheadingH3 = tw.h3`text-2xl sm:text-2xl font-black tracking-wide text-gray-800`
export const SubheadingH4 = tw.h4`text-xl sm:text-xl tracking-wide text-gray-900`
export const HeadingRow = tw.div`flex`
export const SubHeadingRow = tw.div`flex mb-4`
export const SubheadingH3Soft = tw.h3`text-2xl sm:text-2xl tracking-wide text-gray-600`