import React from 'react'
import { useAuth } from 'context/AuthContext'

import 'styles/loader.css'

export default () => {
    const { loading } = useAuth()
    return (
        <>
            {loading && (
                <div className="loader_container">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            )}
        </>
    )
}
