import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl, TwoColumn, Column, ContainerMarginTop } from 'components/misc/Layouts'
import { HeadingRow, Heading, SubheadingH3Soft } from 'components/misc/Headings'
import { RemoveButton, AddButton } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn, ListItemTransparent } from 'components/misc/List'

import CreateFromAdress from 'components/forms/CreateFromAdress'
import CreateFromName from 'components/forms/CreateFromName'

import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'


export default ({
    title = 'Settings',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [createFromNameOpen, setCreateFromNameOpen] = useState(false)
    const toggleCreateFromNameForm = useCallback(() => {
        setCreateFromNameOpen(!createFromNameOpen)
    }, [setCreateFromNameOpen, createFromNameOpen])
    const [createFromEmailOpen, setCreateFromEmailOpen] = useState(false)
    const toggleCreateFromEmailForm = useCallback(() => {
        setCreateFromEmailOpen(!createFromEmailOpen)
    }, [setCreateFromEmailOpen, createFromEmailOpen])
    
    const [fromNames, setFromNames] = useState([])
    const [fromEmails, setFromEmails] = useState([])

    const getFromNames = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/from_name_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setFromNames(data.data)
                } else {
                    setFromNames([])
                }
            })
            .catch(() => {
                toast.error('Error on getting From Names')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setFromNames, toggleLoading])

    const getFromEmails = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/from_email_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setFromEmails(data.data)
                } else {
                    setFromEmails([])
                }
            })
            .catch(() => {
                toast.error('Error on getting From Names')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setFromEmails, toggleLoading])

    const removeFromName = useCallback((id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    id
                }
                toggleLoading(true)
                api.post('/from_name_remove', data)
                    .then(() => {
                        getFromNames()
                        toast.success('Removed')
                    })
                    .catch(() => {
                        toast.error('An error ocurred, try again!')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, getFromNames, toggleLoading])

    const removeFromEmail = useCallback((id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    id
                }
                toggleLoading(true)
                api.post('/from_email_remove', data)
                    .then(() => {
                        getFromEmails()
                        toast.success('Removed')
                    })
                    .catch(() => {
                        toast.error('An error ocurred, try again!')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, getFromEmails, toggleLoading])

    const createFromNameCallback = useCallback(() => {
        toggleCreateFromNameForm()
        getFromNames()
    }, [toggleCreateFromNameForm, getFromNames])

    const createFromEmailCallback = useCallback(() => {
        toggleCreateFromEmailForm()
        getFromEmails()
    }, [toggleCreateFromEmailForm, getFromEmails])

    useEffect(() => {
        getFromNames()
        getFromEmails()
    }, [getFromNames, getFromEmails])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                <HeadingRow>
                    <Heading>{title}</Heading>
                </HeadingRow>

                <ContainerMarginTop tw={`mt-4`}>
                    <TwoColumn>

                        <Column>
                            <ListContainer>
                                <ListItemTransparent>
                                    <LeftColumn>
                                        <SubheadingH3Soft>Sending Names</SubheadingH3Soft>
                                    </LeftColumn>
                                    <RightColumn>
                                        <AddButton onClick={toggleCreateFromNameForm}>Add</AddButton>
                                    </RightColumn>
                                </ListItemTransparent>
                            </ListContainer>
                            
                            {createFromNameOpen && (
                                <CreateFromName callback={createFromNameCallback} />
                            )}

                            <ListContainer>
                                {fromNames && fromNames.map((item) => (
                                    <ListItem key={item.id}>
                                        <LeftColumn>{item.name}</LeftColumn>
                                        <RightColumn>
                                            <RemoveButton onClick={() => removeFromName(item.id)}>Remove</RemoveButton>
                                        </RightColumn>
                                    </ListItem>
                                ))}
                            </ListContainer>
                        </Column>

                        <Column>
                            <ListContainer>
                                <ListItemTransparent>
                                    <LeftColumn>
                                        <SubheadingH3Soft>Sending Emails</SubheadingH3Soft>
                                    </LeftColumn>
                                    <RightColumn>
                                        <AddButton onClick={toggleCreateFromEmailForm}>Add</AddButton>
                                    </RightColumn>
                                </ListItemTransparent>
                            </ListContainer>

                            {createFromEmailOpen && (
                                <CreateFromAdress callback={createFromEmailCallback} />
                            )}

                            <ListContainer>
                                {fromEmails && fromEmails.map((item) => (
                                    <ListItem key={item.id}>
                                        <LeftColumn>{item.email}</LeftColumn>
                                        <RightColumn>
                                            <RemoveButton onClick={() => removeFromEmail(item.id)}>Remove</RemoveButton>
                                        </RightColumn>
                                    </ListItem>
                                ))}
                            </ListContainer>
                        </Column>

                    </TwoColumn>
                </ContainerMarginTop>

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
