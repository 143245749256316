import React, { useCallback, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import tw from 'twin.macro'
import styled from 'styled-components'
import 'styled-components/macro'

import useAnimatedNavToggler from 'helpers/useAnimatedNavToggler'

import logo from 'images/platform_logo.png'
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'

import { useAuth } from 'context/AuthContext'

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`

export const NavLinks = tw.div`inline-block`

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-gray-500 hocus:text-gray-500
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-gray-600 text-gray-100
  hocus:bg-gray-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! hocus:text-gray-600`};

  img {
    ${tw`w-20 mr-3`}
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-gray-500 transition duration-300
`
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

export default ({ roundedHeaderButton = false, className, collapseBreakpointClass = 'lg' }) => {
  const { logout, authenticated } = useAuth()
  const history = useHistory()

  const logoutCallback = useCallback((e) => {
    e.preventDefault()
    logout()
    history.push('/')
  }, [logout, history])

  const links = [
    <NavLinks key={1}>
      <NavLink href='/contacts'>Contacts</NavLink>
      <NavLink href='/lists'>Lists</NavLink>
      <NavLink href='/emails'>Emails</NavLink>
      {authenticated && authenticated.type && authenticated.type === "1" && (
        <Fragment>
          {/* <NavLink href='/sends'>Sends</NavLink> */}
          <NavLink href='/users'>Users</NavLink>
          <NavLink href='/settings'>Settings</NavLink>
        </Fragment>
      )}
      <NavLink href='/account'>Account</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href='/' onClick={logoutCallback}>Logout</PrimaryLink>
    </NavLinks>
  ]

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass]

  const logoLink = (
    <LogoLink href='/dashboard'>
      <img src={logo} alt='Platform logo' />
    </LogoLink>
  )

  return (
    <Header className={className || 'header-light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: '150%', display: 'none' }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? 'open' : 'closed'}>
          {showNavLinks ? <CloseIcon tw='w-6 h-6' /> : <MenuIcon tw='w-6 h-6' />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  )
}

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
}
