import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading } from 'components/misc/Headings'
import { CreateButton, RemoveButton, EditButton } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn } from 'components/misc/List'

import CreateContactModal from 'components/modals/CreateContactV2';
import EditContactModal from 'components/modals/EditContactV2';

import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

export default ({
    title = 'Contacts',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [createContactModalOpen, setCreateContactModalOpen] = useState(false)
    const toggleCreateModal = () => setCreateContactModalOpen(!createContactModalOpen)

    const [editContactModalOpen, setEditContactModalOpen] = useState(false)
    const toggleEditModal = () => setEditContactModalOpen(!editContactModalOpen)

    const [contacts, setContacts] = useState([])
    const [selectedItem, setSelectedItem] = useState()

    const getContacts = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/contact_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setContacts(data.data)
                }
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setContacts, toggleLoading])

    const insertCallback = useCallback(() => {
        getContacts()
    }, [getContacts])

    const removeContact = useCallback((id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    contact_id: id
                }
        
                toggleLoading(true)
                api.post('/contact_remove', data)
                    .then(() => {
                        getContacts()
                        toast.success('Contact removed!')
                    })
                    .catch(() => {
                        console.log('error')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, getContacts, toggleLoading])

    useEffect(() => {
        getContacts()
    }, [getContacts])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                    <HeadingRow>
                        <Heading>{title}</Heading>
                    </HeadingRow>

                    <CreateButton onClick={toggleCreateModal}>
                        Create contact
                    </CreateButton>

                    <ListContainer>
                        {contacts && contacts.map((item) => (
                            <ListItem key={item.id}>
                                <LeftColumn>{item.first_name} <i>[{item.email}]</i></LeftColumn>
                                <RightColumn>
                                    <EditButton onClick={() => {
                                        setSelectedItem(item)
                                        toggleEditModal()
                                    }}>
                                        Edit
                                    </EditButton>
                                    <RemoveButton onClick={() => removeContact(item.id)}>Remove</RemoveButton>
                                </RightColumn>
                            </ListItem>
                        ))}
                    </ListContainer>

                    <CreateContactModal
                        modalIsOpen={createContactModalOpen}
                        toggleModal={toggleCreateModal}
                        callback={insertCallback}
                    />

                    <EditContactModal
                        modalIsOpen={editContactModalOpen}
                        toggleModal={toggleEditModal}
                        item={selectedItem}
                        callback={insertCallback}
                    />

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
