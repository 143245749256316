import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'
import styled from 'styled-components'
import tw from 'twin.macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading } from 'components/misc/Headings'

import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const FormContainer = styled.div `
  ${tw`text-gray-600 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-800 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const Column = tw.div `sm:w-full flex flex-col`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const SubmitButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`
const Input = tw.input ``

export default ({
    title = 'Account',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const submit = useCallback((e) => {
        e.preventDefault()

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            name,
            password
        }
        toggleLoading(true)
        api.post('/user_update', data)
            .then(() => {
                toast.success('User info updated')
            })
            .catch(() => {
                toast.error('Error while updating user')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, name, password, toggleLoading])

    const setUserData = useCallback((data) => {
        const { user_data } = data
        setName(user_data.name)
        setEmail(user_data.email)
    }, [setName, setEmail])

    const getUserData = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/get_user_data', data)
            .then((response) => {
                setUserData(response.data)
            })
            .catch(() => {
                toast.error('Error getting user data')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setUserData, toggleLoading])

    useEffect(() => {
        getUserData()
    }, [getUserData])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                    <HeadingRow>
                        <Heading>{title}</Heading>
                    </HeadingRow>

                    <FormContainer>
                        <form action = '#' autoComplete = 'off'>
                            <Column>
                                <InputContainer>
                                    <Label htmlFor = 'name'>Name</Label>
                                    <Input
                                    id = 'name'
                                    type = 'text'
                                    name = 'name'
                                    onChange = {(e) => setName(e.target.value)}
                                    value = {name}
                                    placeholder = 'John Doe' / >
                                </InputContainer>

                                <InputContainer>
                                    <Label htmlFor = 'email'>Email</Label>
                                    <Input
                                    id = 'email'
                                    type = 'text'
                                    name = 'email'
                                    onChange = {(e) => setEmail(e.target.value)}
                                    value = {email}
                                    disabled = {true}
                                    placeholder = 'john@doe.com' / >
                                </InputContainer>

                                <InputContainer>
                                    <Label htmlFor = 'link_preview'>Password</Label>
                                    <Input
                                    id = 'password'
                                    type = 'password'
                                    name = 'password'
                                    onChange = {(e) => setPassword(e.target.value)}
                                    value = {password} / >
                                </InputContainer>
                                
                            </Column>

                            <SubmitButton onClick={submit}>Submit</SubmitButton>
                        </form>
                    </FormContainer>

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
