import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import tw from 'twin.macro'
import ReactModalAdapter from 'helpers/ReactModalAdapter'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-700 outline-none shadow-2xl`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-100 text-white`
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const InputContainer = tw.div`relative py-5 mt-6`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`
const Column = tw.div`sm:w-5/12 flex flex-col`
const Input = tw.input``
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`

export default ({
  modalIsOpen,
  toggleModal,
  item,
  callback
}) => {
  const { authenticated, toggleLoading } = useAuth()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const submit = useCallback((e) => {
    e.preventDefault()

    const data = {
      email: authenticated.email,
      login_key: authenticated.login_key,
      contact_id: item.id,
      first_name: firstName,
      contact_email: email,
      last_name: lastName,
      phone
    }

    toggleLoading(true)
    api.post('/contact_edit', data)
      .then(() => {
        toast.success('Contact edited!')
        if (callback) callback()
        toggleModal()
      })
      .catch(() => {
        toast.error('An error ocurred, try again!')
      })
      .finally(() => {
        toggleLoading(false)
      })

  }, [authenticated, firstName, lastName, email, phone, item, callback, toggleModal, toggleLoading])

  useEffect(() => {
    if (item) {
      setFirstName(item.first_name);
      setLastName(item.last_name);
      setEmail(item.email);
      setPhone(item.phone);
    }
  }, [item, setFirstName, setLastName, setEmail, setPhone])

  return (
    <StyledModal
      closeTimeoutMS={300}
      className='mainHeroModal'
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
    >
      <CloseModalButton onClick={toggleModal}>
        <CloseIcon tw='w-6 h-6' />
      </CloseModalButton>
      <div className='content'>
        <FormContainer>
          <div tw='mx-auto max-w-4xl'>
            <h2>Edit Contact</h2>
            <form action='#'>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor='first_name'>First name</Label>
                    <Input
                      id='first_name'
                      type='text'
                      name='first_name'
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      placeholder='Mr. Joe' />
                  </InputContainer>

                  <InputContainer>
                    <Label htmlFor='last_name'>Last name</Label>
                    <Input
                      id='last_name'
                      type='text'
                      name='last_name'
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      placeholder='Sanders' />
                  </InputContainer>
                </Column>

                <Column>
                  <InputContainer>
                    <Label htmlFor='email'>Email address</Label>
                    <Input
                      id='email'
                      type='email'
                      name='email'
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder='joe@google.com' />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor='phone'>Phone number</Label>
                    <Input
                      id='phone'
                      type='text'
                      name='phone'
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      placeholder='+551132405699' />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton onClick={submit}>Submit</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </div>
    </StyledModal>
  )
}
