import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import 'styled-components/macro';
import tw from 'twin.macro';
import ReactModalAdapter from 'helpers/ReactModalAdapter';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useAuth } from 'context/AuthContext';
import { useForm } from "react-hook-form";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-700 outline-none shadow-2xl`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-100 text-white`;
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const Input = tw.input``;
const Select = tw.select``;
const Option = tw.option``;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

export default ({
  modalIsOpen,
  toggleModal,
  callback
}) => {
  const { authenticated, toggleLoading } = useAuth();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const [lists, setLists] = useState([]); 

  const onSubmit = useCallback((data) => {
    const formData = {
      email: authenticated.email,
      login_key: authenticated.login_key,
      first_name: data.first_name,
      last_name: data.last_name,
      contact_email: data.email,
      list_id: data.list_id,
      phone: data.phone
    };

    toggleLoading(true);
    api.post('/contact_insert', formData)
      .then(() => {
        toast.success('Contact inserted!');
        if (reset) reset();
        if (callback) callback();
      })
      .catch(() => {
        toast.error('An error ocurred, try again!');
      });
    toggleLoading(false);
  }, [authenticated, reset, toggleLoading, callback])

  const getLists = useCallback(() => {
    const data = {
      email: authenticated.email,
      login_key: authenticated.login_key,
    }
    toggleLoading(true)
    api.post('/list_list', data)
      .then((response) => {
        const { data } = response
        if (data.data) {
          setLists(data.data);
        }
      })
      .catch(() => {
        console.log('error')
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [authenticated, setLists, toggleLoading])

  useEffect(() => {
    getLists()
  }, [getLists])

  return (
    <StyledModal
      closeTimeoutMS={300}
      className='mainHeroModal'
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
    >
      <CloseModalButton onClick={toggleModal}>
        <CloseIcon tw='w-6 h-6' />
      </CloseModalButton>
      <div className='content'>
        <FormContainer>
          <div tw='mx-auto max-w-4xl'>
            <h2>Create a Contact</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor='first_name'>First name</Label>
                    <Input
                      id='first_name'
                      name='first_name'
                      type='text'
                      placeholder='Mr. Joe'
                      {...register('first_name', { required: 'First name is required' })}
                    />
                    {errors.first_name && <span>{errors.first_name?.message}</span>}
                  </InputContainer>

                  <InputContainer>
                    <Label htmlFor='last_name'>Last name</Label>
                    <Input
                      id='last_name'
                      name='last_name'
                      type='text'
                      placeholder='Sanders'
                      {...register('last_name', { required: false })}
                    />
                  </InputContainer>

                  <InputContainer>
                    <Label htmlFor='list_id'>List</Label>
                    <Select
                      id='list_id'
                      name='list_id'
                      {...register('list_id', { required: 'List is required' })}
                    >
                      {lists.map((item) => (
                        <Option value={item.id} key={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                    {errors.list_id && <span>{errors.list_id?.message}</span>}
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor='email'>Email address</Label>
                    <Input
                      id='email'
                      name='email'
                      type='email'
                      placeholder='joe@google.com'
                      {...register('email', { required: 'Email is required' })}
                    />
                    {errors.email && <span>{errors.email?.message}</span>}
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor='phone'>Phone number</Label>
                    <Input
                      id='phone'
                      name='phone'
                      type='text'
                      placeholder='+....'
                      {...register('phone', { required: false })}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton onClick={handleSubmit(onSubmit)}>Submit</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </div>
    </StyledModal>
  )
}
