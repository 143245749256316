import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'context/AuthContext';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    isPublic,
    ...rest
}) {
    const { authenticated } = useAuth();    

    let signed = null;
    if (authenticated) {
        signed = true;
    } else {
        signed = false;
    }

    if (!signed && isPrivate) {
        return <Redirect to='/' />
    }

    if (signed && !isPrivate && !isPublic) {
        return <Redirect to='/dashboard' />;
    }

    return (
        <Route {...rest}>
            <Component />
        </Route>
    );
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
};
