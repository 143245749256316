import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "styled-components/macro";
import { LinkButton } from "components/misc/LinkButton";

const Button = tw(LinkButton)`flex mt-4 flex-col`;
const Container = tw.div`relative`;
const ThreeColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-10`}
`;
const Column = styled.div`
    ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
    ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-gray-500 rounded-lg mt-12`}
    .imageContainer {
        ${tw`border-2 border-gray-500 text-center rounded-full p-6 flex-shrink-0 relative`}
        img {
        ${tw`w-8 h-8`}
        }
    }

    .textContainer {
        ${tw`mt-6 text-center`}
    }

    .title {
        ${tw`mt-2 font-bold text-xl leading-none text-gray-800`}
    }

    .description {
        ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
    }
`;

export default () => {

    const cards = [
        { title: "Contacts", description: "Manage the contacts of the platform", link: "/contacts" },
        { title: "Lists", description: "Manage the lists of the platform", link: "/lists" },
        { title: "Emails", description: "Manage the emails of the platform", link: "/emails" },
        // { title: "Templates", description: "Manage the templates of the platform", link: "/templates" },
        // { title: "Sends", description: "Manage the sends of the platform", link: "/sends" },
    ];

    return (
        <Container>
            <ThreeColumnContainer>
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
                        <span className="textContainer">
                            <span className="title">{card.title}</span>
                            <p className="description">
                                {card.description}
                            </p>
                            <Button href={card.link}>
                                Access
                            </Button>                            
                        </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
        </Container>
    );
};
