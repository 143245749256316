import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import tw from 'twin.macro'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'
import AceEditor from "react-ace"

import "ace-builds/webpack-resolver"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-monokai"

const Content = tw.div `rounded bg-gray-700 outline-none xl:mx-auto p-4 my-4`
const FormContainer = styled.div `
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const Column = tw.div `sm:w-full flex flex-col`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const SubmitButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`
const CancelButton = tw.button `w-full sm:w-32 mt-6 py-3 bg-gray-100 text-red-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-red-700 hocus:-translate-y-px hocus:shadow-xl ml-4`
const Input = tw.input ``
const Select = tw.select``
const Option = tw.option``

export default ({ toggle, callback }) => {
    const { authenticated, toggleLoading } = useAuth()
    const [title, setTitle] = useState('')
    const [linkTemplate, setLinkTemplate] = useState('')
    const [linkPreview, setLinkPreview] = useState('')
    const [content, setContent] = useState('')
    
    const [hasCustomContent, setHasCustomContent] = useState(0)
    const [hasCustomLink, setHasCustomLink] = useState(0)

    const enableLinkTemplate = false
    const enableLinkPreview = false
    const enableContent = true

    const clearForm = useCallback(() => {
        setTitle('')
        setLinkTemplate('')
        setLinkPreview('')
        setContent('')
    }, [setTitle, setLinkTemplate, setLinkPreview, setContent])

    const submit = useCallback((e) => {
        e.preventDefault()

        let valid = true
        if (title.length < 5) {
            toast.warning('Please insert a valid title')
            valid = false
        }

        if (!valid) return

        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
            title,
            link_template: linkTemplate,
            link_preview: linkPreview,
            content,
            has_custom_content: hasCustomContent,
            has_custom_link: hasCustomLink
        }

        toggleLoading(true)
        api.post('/email_insert', data)
            .then(() => {
                toast.success('Email inserted!')
                clearForm()
                if (callback) callback()
                if (toggle) toggle()
            })
            .catch(() => {
                toast.error('An error ocurred, try again!')
            })
            .finally(() => {
                toggleLoading(false)
            })

    }, [authenticated, title, linkTemplate, linkPreview, content, hasCustomContent, hasCustomLink, callback, toggle, clearForm, toggleLoading])

    return (
        <Content>
            <FormContainer>
                <div tw = 'mx-auto max-w-4xl'>
                    <h2>Create Email</h2>
                    <form action = '#'>
                        <Column>
                            <InputContainer>
                                <Label htmlFor = 'title'>Title</Label>
                                <Input
                                id = 'title'
                                type = 'text'
                                name = 'title'
                                onChange = {(e) => setTitle(e.target.value)}
                                value = {title}
                                placeholder = 'Marketing Sales' / >
                            </InputContainer>

                            {enableLinkTemplate && (
                                <InputContainer>
                                    <Label htmlFor = 'link_template'>Link template</Label>
                                    <Input
                                    id = 'link_template'
                                    type = 'text'
                                    name = 'link_template'
                                    onChange = {(e) => setLinkTemplate(e.target.value)}
                                    value = {linkTemplate}
                                    placeholder = 'https://link.to/file.html' / >
                                </InputContainer>
                            )}
                            
                            {enableLinkPreview && (
                                <InputContainer>
                                    <Label htmlFor = 'link_preview'>Link Preview</Label>
                                    <Input
                                    id = 'link_preview'
                                    type = 'text'
                                    name = 'link_preview'
                                    onChange = {(e) => setLinkPreview(e.target.value)}
                                    value = {linkPreview}
                                    placeholder = 'https://link.to/linkpreview.jpg' / >
                                </InputContainer>
                            )}

                            {enableContent && (
                                <InputContainer>
                                    <AceEditor
                                        placeholder="Placeholder Text"
                                        mode="html"
                                        theme="monokai"
                                        name="blah2"
                                        onChange={(e) => setContent(e)}
                                        fontSize={14}
                                        showPrintMargin={true}
                                        showGutter={true}
                                        highlightActiveLine={true}
                                        value={content}
                                        setOptions={{
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }}
                                        />
                                </InputContainer>
                            )}
                            
                            <InputContainer>
                                <Label htmlFor='has_custom_content'>Has custom content</Label>
                                <Select 
                                    id='has_custom_content'
                                    name='has_custom_content'
                                    onChange={(e) => setHasCustomContent(e.target.value)}>
                                        <Option value={0}>No</Option>
                                        <Option value={1}>Yes</Option>
                                </Select>
                            </InputContainer>

                            <InputContainer>
                                <Label htmlFor='has_custom_link'>Has custom link</Label>
                                <Select 
                                    id='has_custom_link'
                                    name='has_custom_link'
                                    onChange={(e) => setHasCustomLink(e.target.value)}>
                                        <Option value={0}>No</Option>
                                        <Option value={1}>Yes</Option>
                                </Select>
                            </InputContainer>
                            
                        </Column>

                        <SubmitButton onClick={submit}>Submit</SubmitButton>
                        <CancelButton onClick={toggle}>Cancel</CancelButton>
                    </form>
                </div>
            </FormContainer>
        </Content>
    )
}
