import React, { useState, useCallback, useEffect } from 'react'
import 'styled-components/macro'

import AnimationRevealPage from 'helpers/AnimationRevealPage'
import Header from 'components/headers/MainHeader'
import Footer from 'components/footers/MainFooter'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import { HeadingRow, Heading } from 'components/misc/Headings'
import { CreateButton, EditButton, RemoveButton, ManageButtonLink } from 'components/misc/Buttons'
import { ListContainer, ListItem, LeftColumn, RightColumn } from 'components/misc/List'

import CreateListModal from 'components/modals/CreateList'
import EditListModal from 'components/modals/EditList'

import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

export default ({
    title = 'Lists',
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [createListModalOpen, setCreateListModalOpen] = useState(false)
    const toggleCreateModal = () => setCreateListModalOpen(!createListModalOpen)

    const [editListModalOpen, setEditListModalOpen] = useState(false)
    const toggleEditModal = () => setEditListModalOpen(!editListModalOpen)

    const [lists, setLists] = useState([])
    const [selectedItem, setSelectedItem] = useState()

    const getLists = useCallback(() => {
        const data = {
            email: authenticated.email,
            login_key: authenticated.login_key,
        }
        toggleLoading(true)
        api.post('/list_list', data)
            .then((response) => {
                const { data } = response
                if (data.data) {
                    setLists(data.data)
                }
            })
            .catch(() => {
                console.log('error')
            })
            .finally(() => {
                toggleLoading(false)
            })
    }, [authenticated, setLists, toggleLoading])

    const insertCallback = useCallback(() => {
        getLists()
    }, [getLists])

    const removeList = useCallback((id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, delete it!`
        })
        .then((result) => {
            if (result.isConfirmed) {
                const data = {
                    email: authenticated.email,
                    login_key: authenticated.login_key,
                    list_id: id
                }
        
                toggleLoading(true)
                api.post('/list_remove', data)
                    .then(() => {
                        getLists()
                        toast.success('List removed!')
                    })
                    .catch(() => {
                        console.log('error')
                    })
                    .finally(() => {
                        toggleLoading(false)
                    })
            }
        })
    }, [authenticated, getLists, toggleLoading])

    useEffect(() => {
        getLists()
    }, [getLists])

    return (
        <AnimationRevealPage disabled={true}>
            <Header />
            <Container>
            <ContentWithPaddingXl>

                    <HeadingRow>
                        <Heading>{title}</Heading>
                    </HeadingRow>

                    <CreateButton onClick={toggleCreateModal}>
                        Create list
                    </CreateButton>

                    <ListContainer>
                        {lists && lists.map((item) => (
                            <ListItem key={item.id}>
                                <LeftColumn>{item.name}</LeftColumn>
                                <RightColumn>
                                    <ManageButtonLink href={`/manage/${item.id}`}>
                                        Manage
                                    </ManageButtonLink>
                                    <EditButton onClick={() => {
                                        setSelectedItem(item)
                                        toggleEditModal()
                                    }}>
                                        Edit
                                    </EditButton>
                                    <RemoveButton onClick={() => removeList(item.id)}>Remove</RemoveButton>
                                </RightColumn>
                            </ListItem>
                        ))}
                    </ListContainer>

                    <CreateListModal
                        modalIsOpen={createListModalOpen}
                        toggleModal={toggleCreateModal}
                        callback={insertCallback}
                    />

                    <EditListModal
                        modalIsOpen={editListModalOpen}
                        toggleModal={toggleEditModal}
                        item={selectedItem}
                        callback={insertCallback}
                    />

            </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    )
}
