import React, { createContext, useState, useCallback, useContext } from 'react';
import api from 'services/api';

const AuthContext = createContext();
const APP_ID = process.env.REACT_APP_APP_ID;

function AuthProvider({ children }) {
    const [loading, setLoading] = useState(null);

    const toggleLoading = useCallback((state) => {
        setLoading(state);
    }, []);

    const [authenticated, setAuthenticated] = useState(() => {
        const user = localStorage.getItem(`@${APP_ID}:user`);
        if (user) {
            const userData = JSON.parse(user);
            return userData;
        }
    });

    const signIn = useCallback(async ({ email, password }) => {
        try {
            const response = await api.post('/user_login', {
                email,
                password,
            });            
            const { data } = response;
            const saveData = { email: data.email, login_key: data.login_key, type: data.type };
            
            localStorage.setItem(`@${APP_ID}:user`, JSON.stringify(saveData));
            
            setAuthenticated(saveData);
        } catch (err) {
            return Promise.reject(err)
        }
    }, []);

    const logout = useCallback(async () => {
        setAuthenticated();
        localStorage.removeItem(`@${APP_ID}:user`);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                signIn,
                logout,
                toggleLoading,
                loading
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(AuthContext);

    return context;
}

export { useAuth, AuthProvider };
