import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'routes/Route'

import Login from 'pages/Login'
import ForgotPassword from 'pages/ForgotPassword'
import Dashboard from 'pages/Dashboard'
import Lists from 'pages/Lists'
import Contacts from 'pages/Contacts'
import Emails from 'pages/Emails'
import Sends from 'pages/Sends'
import Account from 'pages/Account'
import Manage from 'pages/Manage'
import Users from 'pages/Users'
import Settings from 'pages/Settings'

export default function Routes() {
    return (
        <Switch>

          <Route path='/dashboard' exact isPrivate component={Dashboard} />
          <Route path='/lists' exact isPrivate component={Lists} />
          <Route path='/contacts' exact isPrivate component={Contacts} />
          <Route path='/emails' exact isPrivate component={Emails} />
          <Route path='/sends' exact isPrivate component={Sends} />
          <Route path='/account' exact isPrivate component={Account} />
          <Route path='/manage/:id' exact isPrivate component={Manage} />
          <Route path='/users' exact isPrivate component={Users} />
          <Route path='/settings' exact isPrivate component={Settings} />

          <Route path='/forgotpassword' exact component={ForgotPassword} />
          <Route path='/' exact component={Login} />

        </Switch>
    );
}
