import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container as ContainerBase } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import 'styled-components/macro'
import LogoFull from 'images/platform_logo_full.png'
import logo from 'images/platform_logo.png'

const Container = tw(ContainerBase)`min-h-screen bg-gray-900 text-white font-medium flex justify-center -m-8`
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`
const LogoLink = tw.a``
const LogoImage = tw.img`h-20 mx-auto`
const MainContent = tw.div`mt-12 flex flex-col items-center`
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`
const IllustrationImage = styled.div`
  ${props => `background-image: url('${props.imageSrc}');`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`

export default ({
    logoLinkUrl = '/',
    headingText = 'Forgot password',
    loginUrl = '/'
}) => {

    return (
        <AnimationRevealPage disabled={true}>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink href={logoLinkUrl}>
                            <LogoImage src={logo} />
                        </LogoLink>

                        <MainContent>
                            <Heading>{headingText}</Heading>
                            <p tw='mt-5 text-2xl text-gray-600 text-center'>
                                To recover your password, contact the administrador. Thank you!
                            </p>
                            <p tw='mt-6 text-xs text-gray-600 text-center'>
                                <a href={loginUrl} tw='border-b border-gray-500 border-dotted'>
                                Return to login
                                </a>
                            </p>
                        </MainContent>

                    </MainContainer>

                    <IllustrationContainer>
                        <IllustrationImage imageSrc={LogoFull} />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    )
}