import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import 'styled-components/macro'
import tw from 'twin.macro'
import ReactModalAdapter from 'helpers/ReactModalAdapter'
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'
import { toast } from 'react-toastify'
import api from 'services/api'
import { useAuth } from 'context/AuthContext'

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-700 outline-none shadow-2xl`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-100 text-white`
const FormContainer = styled.div `
  ${tw`p-10 sm:p-12 md:p-16 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea,select {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  select option {
    ${tw`text-gray-800 py-2`}
  }
`
const InputContainer = tw.div `relative py-5 mt-6`
const Label = tw.label `absolute top-0 left-0 tracking-wide font-semibold text-sm`
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`
const Column = tw.div`sm:w-5/12 flex flex-col`
const Input = tw.input``
const Select = tw.select``
const Option = tw.option``
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`

export default ({
    modalIsOpen,
    toggleModal,
    callback
}) => {
    const { authenticated, toggleLoading } = useAuth()

    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [type, setType] = useState('')
    const [email, setEmail] = useState('')

    const reset = useCallback(() => {
        if (callback) callback()
        if (toggleModal) toggleModal()
        setName('')
        setEmail('')
        setPassword('')
        setType('')
    }, [callback, toggleModal, setName, setEmail, setPassword, setType])

    const submit = useCallback((e) => {
        e.preventDefault()

        let valid = true

        if (name.length < 5) {
        toast.warning('Please insert a valid name')
        valid = false
        }
        if (email.length < 3) {
            toast.warning('Please insert a valid email')
            valid = false
        }
        if (password.length < 3) {
            toast.warning('Please insert a valid password')
            valid = false
        }
        if (type === '') {
            toast.warning('Please insert a type for new user')
            valid = false
        }

        if (!valid) return

        const data = {
        email: authenticated.email,
        login_key: authenticated.login_key,
        user_name: name,
        user_email: email,
        user_password: password,
        user_type: type
        }

        toggleLoading(true)
        api.post('/user_register', data)
        .then(() => {
            toast.success('User inserted!')
            reset()
        })
        .catch((err) => {
            const message = 
                (err && err.response && err.response.data && err.response.data.message) ?
                err.response.data.message :
                'An error ocurred, try again!'
            toast.error(message)
        })
        .finally(() => {
            toggleLoading(false)
        })
        
    }, [authenticated, name, email, password, type, reset, toggleLoading])

    return (
        <StyledModal
            closeTimeoutMS={300}
            className='mainHeroModal'
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            shouldCloseOnOverlayClick={true}
            >
            <CloseModalButton onClick={toggleModal}>
                <CloseIcon tw='w-6 h-6' />
            </CloseModalButton>
            <div className='content'>
                <FormContainer>
                    <div tw='mx-auto max-w-4xl'>
                        <h2>Create User</h2>
                        <form action='#'>
                        <TwoColumn>
                            <Column>

                                <InputContainer>
                                    <Label htmlFor='name'>Name</Label>
                                    <Input 
                                    id='name' 
                                    type='text' 
                                    name='name'
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    placeholder='Mr. Joe' />
                                </InputContainer>

                                <InputContainer>
                                    <Label htmlFor='email'>Email address</Label>
                                    <Input 
                                    id='email' 
                                    type='email' 
                                    name='email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    placeholder='joe@google.com' />
                                </InputContainer>

                                <InputContainer>
                                    <Label htmlFor='password'>Password</Label>
                                    <Input 
                                    id='password' 
                                    type='text' 
                                    name='password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    placeholder='******' />
                                </InputContainer>

                            </Column>

                            <Column>
                            
                                <InputContainer>
                                    <Label htmlFor='type'>Type</Label>
                                    <Select 
                                    id='type' 
                                    onChange={(e) => setType(e.target.value)}
                                    name='type'
                                    value={type}>
                                        <Option value="">Select</Option>
                                        <Option value="2">Normal</Option>
                                        <Option value="1">Administrator</Option>
                                    </Select>
                                </InputContainer>
                            
                            </Column>

                        </TwoColumn>

                        <SubmitButton onClick={submit}>Submit</SubmitButton>
                        </form>
                    </div>
                </FormContainer>
            </div>
        </StyledModal>
    )
}
