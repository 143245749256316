import tw from "twin.macro"

export const Container = tw.div`relative`
export const ContainerMarginTop = tw.div`relative mt-5`
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`

export const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`
export const Column = tw.div`sm:w-5/12 flex flex-col`